import Project01img from "../../assets/img/portfolio/Project-1-PizzaOrder/PizzaOrderImg01.png";
import Project01video from "../../assets/img/portfolio/Project-1-PizzaOrder/PizzaOrderVideo.mp4";

import Project02img from "../../assets/img/portfolio/Project-2-AiSummarizer/AiSummarizerImg01.png";
import Project02video from "../../assets/img/portfolio/Project-2-AiSummarizer/AiSummarizerVideo.webm";

import Project03img from "../../assets/img/portfolio/Project-3-TravelList/TravelListImg01.png";
import Project03video from "../../assets/img/portfolio/Project-3-TravelList/TravelListVideo01.webm";

import Project04img01 from "../../assets/img/portfolio/project-4-Worldwise/img01.png";
import Project04img02 from "../../assets/img/portfolio/project-4-Worldwise/img02.png";
import Project04img03 from "../../assets/img/portfolio/project-4-Worldwise/img03.png";

import Project05img from "../../assets/img/portfolio/project-5-DreamNokree/img01.png";
import Project05video from "../../assets/img/portfolio/project-5-DreamNokree/Video01.webm";

import Project06img from "../../assets/img/portfolio/Project-6-HMS/hms01.png";

const PortfolioData = [
  {
    id: 1,
    type: "Pizza Order React App",
    name: "local",
    image: Project01img,

    tag: ["React"],
    delayAnimation: "0",
    videom: Project01video,
    modalDetails: [
      {
        project: "React",
        client: "Practice Project",
        language: "🟡React.js 🟡Tailwind-Css 🟡ReduxToolkit 🟡Api",
        preview: "www.github.com/Pizza",
        link: "https://github.com/saqlainzaheer/Pizza-Order-React-App",
        livePreview: "www.pizaorder.com",
        liveLink: "https://react-pizza-saqlain-project-008.netlify.app/",
      },
    ],
  },
  {
    id: 2,
    type: "AI-Article Summarizer",
    name: "local",
    image: Project02img,

    tag: ["React"],
    delayAnimation: "0",
    videom: Project02video,
    modalDetails: [
      {
        project: "React",
        client: "Practice Project",
        language: "🟡React.js 🟡Tailwind-Css 🟡Api 🟡GPT-04",
        preview: "www.github.com/su...",
        link: "https://github.com/saqlainzaheer/AISummarizer-ReactApp",
        livePreview: "www.Summarizer.com",
        liveLink: "https://aisummarizer-reactapp.netlify.app/",
      },
    ],
  },
  {
    id: 3,
    type: "TRAVEL LIST REACT APP",
    name: "local",
    image: Project03img,
    tag: ["React"],
    delayAnimation: "0",
    videom: Project03video,
    modalDetails: [
      {
        project: "React",
        client: "Practice Project",
        language: "🟡React.js 🟡HTTML 🟡CSS",
        preview: "www.github.com/tr...",
        link: "https://github.com/saqlainzaheer/Travel-List-React-App",
        livePreview: "www.travelApp.com",
        liveLink: "https://faraway-travel.netlify.app/",
      },
    ],
  },
  {
    id: 4,
    type: "World Wise React app",
    name: "slider",
    image: Project04img03,
    Slideimage: [Project04img01, Project04img02, Project04img03],
    tag: ["React"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "React App",
        client: "Practice Project",
        language: "🟡React.js 🟡HTTML 🟡CSS 🟡Javascript",
        preview: "www.github.com/wo...",
        link: "https://github.com/saqlainzaheer/WorldWise-React-App",
        livePreview: "Not Avaliable",
        liveLink: "Not Avaliable",
      },
    ],
  },
  {
    id: 5,
    type: "Job Finder",
    name: "local",
    image: Project05img,

    tag: ["Wordpress"],
    delayAnimation: "0",
    videom: Project05video,
    modalDetails: [
      {
        project: "PHP-Wordpress",
        client: "Local FaceBook",
        language: "🟡PHP 🟡Theme Customization 🟡Deployment",
        preview: "Not Avaliable",
        link: "Not Avaliable",
        livePreview: "www.nokree.com",
        liveLink: "https://www.dreamnokree.com/",
      },
    ],
  },
  {
    id: 6,
    type: "Hotel Managment System",
    name: "youtube",
    image: Project06img,
    tag: ["DotNet"],
    videom: "https://youtu.be/MzqYpLwOhwo",
    delayAnimation: "0",
    modalDetails: [
      {
        project: "DotNet-Csharp",
        client: " Semester Project",
        language: "🟡C# 🟡OOP(principles) 🟡SQL 🟡DOTNET  🟡Windows Form",
        preview: "www.github.com/hm...",
        link: "https://github.com/saqlainzaheer/Hotel-Mnagment-System-Csharp",
        livePreview: "Not Avaliable",
        liveLink: "Not Avaliable",
      },
    ],
  },
  // -------------------------------------------------
  // {
  //   id: 2,
  //   type: "mockup project",
  //   name: "mockup",
  //   image: Image1,
  //   tag: ["mockup"],
  //   delayAnimation: "0",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Envato",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.envato.com",
  //       link: "https://www.envato.com/",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   type: "youtube project",
  //   name: "youtube",
  //   image: Image2,
  //   tag: ["video"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "video",
  //       client: "Videohive",
  //       language: " Adobe After Effects",
  //       preview: "www.videohive.net",
  //       link: "https://www.videohive.net",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   type: "slider project",
  //   name: "slider",
  //   image: Image1,
  //   Slideimage: [Image1, Image2, Image3],
  //   tag: [],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Themeforest",
  //       language: " HTML, CSS, Javascript",
  //       preview: "www.envato.com",
  //       link: "https://www.envato.com",
  //     },
  //   ],
  // },
  // ---------------------------------------------------
  // {
  //   id: 5,
  //   type: "saas project",
  //   name: "mokup",
  //   image: Image5,
  //   tag: ["logo"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "Web Application",
  //       client: "Themeforest",
  //       language: "HTML, CSS, ReactJS",
  //       preview: "www.envato.com",
  //       link: "https://themeforest.net/item/deski-saas-software-react-template/33799794",
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   type: "mockup project",
  //   name: "mockup",
  //   image: Image6,
  //   tag: ["logo", "mockup"],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Themeforest",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.pexels.com",
  //       link: "https://www.pexels.com",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   type: "facebook project",
  //   name: "mockup",
  //   image: Image7,
  //   tag: ["logo"],
  //   delayAnimation: "0",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Facebook",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.facebook.com",
  //       link: "https://www.facebook.com/ibthemes",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   type: "dribble project",
  //   name: "mockup",
  //   image: Image8,
  //   tag: ["graphic design"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Dribbble",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.dribbble.com",
  //       link: "https://dribbble.com/ib-themes",
  //     },
  //   ],
  // },
  // {
  //   id: 9,
  //   type: "behence project",
  //   name: "mockup",
  //   image: Image9,
  //   tag: ["graphic design", "mockup"],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Behance",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.behance.com",
  //       link: "https://www.behance.net/ib-themes",
  //     },
  //   ],
  // },
];

export default PortfolioData;
