import React from "react";

const educationContent = [
  {
    year: "2021-2025..",
    degree: "B.S - SOFTWARE ENGINEERING ",
    institute: "National textile UNIVERSITY",
    details: `Passionate about software development, I enrolled in the Software Engineering program at NTU, where I am currently maintaining a solid CGPA of 3.39 out of 4.`,
  },
  {
    year: "2019-2021",
    degree: "F.S.C PRE-ENGINEERING",
    institute: "STUDENTS'INN COLLEGE",
    details: `Continuing my academic journey, I pursued FSc Pre-Engineering and excelled with an impressive 88% marks.`,
  },
  {
    year: "2017-2019",
    degree: "Matricualtion ",
    institute: "White Rose HIGH SCHOOL",
    details: `I achieved a remarkable 90% in my matriculation exams, showcasing my dedication to academic excellence.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
